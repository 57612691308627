@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&family=Play:wght@700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        @apply font-Montserrat;
    }

}

.link {
    @apply p-2.5 flex items-center rounded-md font-medium gap-6 cursor-default md:cursor-pointer duration-300;
}
.active {
    @apply bg-blue-100 text-blue-600;
}
